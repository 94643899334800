import api from '../../utils/api'
 
// 授权
export async function consultation_list (payload) {
  return api.get(`/api/v1/mp/triage/questionnaire`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/questionnaire`,payload)
}


export async function introduction_text (payload) {
  return api.get(`/api/v1/mp/triage/symptom/${payload}`)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/symptom/${payload}`)
}

export async function last_consultation (payload) {
  return api.get(`/api/v1/mp/triage/last`,payload)
  // return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/last`, payload)
}

export async function family_info (payload) {
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}

export async function store_consultation (payload) {
  return api.post(`/api/v1/mp/triage/questionnaire`,payload)
  // return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/questionnaire`, payload)
}

export async function set_default_relation (payload) {
  return api.post(`/api/v1/mp/families/relationships/defaults`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships/defaults`,payload)
}

export async function cancel_last (payload) {
  return api.post(`/api/v1/mp/triage/${payload}/done`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships/defaults`,payload)
}

export async function test (payload) {
  console.log(payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          sub_symptoms: {
            "发作方式":Array[5],
            "缓解条件":Array[4],
            "程度分类":Array[2],
            "部位分类":Array[6],
            "诱因分类":Array[5],
            "发作时间":Array[1],
            "性状分类":Array[8],
            "加重条件":Array[1]
          }
        }
      })
    }, 500)
  })
  // return api.delete('/api/v1/user/login')
}



