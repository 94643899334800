<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="consultation_box">
        <div class="header">
          <div>姓名: {{ origin.name }}</div>
          <div>性别：{{ origin.gender_label }}</div>
          <div>年龄：{{ format_birthDay(origin.birthday) }}岁</div>
          <img
            src="../../assets/img/consultation/con-switch.png"
            alt=""
            width="20"
            @click="showFamily = true"
          />
        </div>
        <div class="serch_out">
          <van-search
            shape="round"
            v-model.trim="value"
            placeholder="搜索检验检查如血常规、胸片等"
          />
        </div>
      </div>

      <div class="main">
        <div class="title_box">
          <div
            :class="['title', {active: title_index === -1}]"
            v-if="this.normal_show"
            @click="title_index = -1"
          >
            <img
              :src="`./img/consultation-img/${
                title_index === -1 ? 'a' : 'c'
              }1.png`"
              alt=""
              class="title_icon"
              width="23"
            />
            <p>常见症状</p>
            <div v-if="normal_num > 0" class="badge">{{ normal_num }}</div>
          </div>
          <div
            :class="['title', {active: idx === title_index}]"
            v-for="(item, idx) in data.parts"
            :key="idx"
            v-show="item.show"
            @click="switch_title(item, idx)"
          >
            <img
              :src="`./img/consultation-img/${idx === title_index ? 'a' : 'c'}${
                img_arr.indexOf(item.name) + 1
              }.png`"
              alt=""
              class="title_icon"
              width="23"
            />
            <p>{{ item.name }}</p>
            <div v-if="item.num > 0" class="badge">{{ item.num }}</div>
          </div>
        </div>

        <div class="symptoms_box" v-if="title_index === -1">
          <div class="sub_title">--常见症状--</div>
          <div class="sub_symptoms_box">
            <div class="sub_outer" v-for="(item, idx) in normal" :key="idx">
              <div
                v-show="item.show"
                :class="['sub_symptoms', {active: item.exist === true}]"
                @click="switch_symptoms(item)"
              >
                {{ item.display }}
                <div
                  class="dots_img"
                  v-if="item.have_introduction"
                  @click.stop="open_tips(item, idx)"
                ></div>
              </div>
              <div class="least_outer" v-if="item.exist">
                <div v-for="(group, index) in item.sub_symptoms" :key="index">
                  <div
                    class="least_symptoms"
                    v-for="(item, idx) in group.items"
                    :key="idx"
                    v-show="item.exist"
                  >
                    <div
                      class="close_least"
                      @click.stop="remove_least(item)"
                    ></div>
                    <p>{{ item.display }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="symptoms_box s2" v-if="title_index > -1">
          <div class="sub_symptoms_box">
            <div
              class="sub_outer"
              v-for="(item, idx) in symptoms"
              :key="idx"
              v-show="item.show"
            >
              <div
                :class="['sub_symptoms', {active: item.exist === true}]"
                @click="switch_symptoms(item, idx)"
              >
                {{ item.display }}
                <div
                  class="dots_img"
                  v-if="item.have_introduction"
                  @click.stop="open_tips(item, idx)"
                ></div>
              </div>
              <div class="least_outer" v-if="item.exist">
                <div v-for="(group, index) in item.sub_symptoms" :key="index">
                  <div
                    class="least_symptoms"
                    v-for="(item, idx) in group.items"
                    :key="idx"
                    v-show="item.exist"
                  >
                    <div
                      class="close_least"
                      @click.stop="remove_least(item)"
                    ></div>
                    <p>{{ item.display }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom_box">
        <div class="bottom_other">
          <div class="bottom_text">请仔细选择您的症状</div>
          <div
            :class="['bottom_btn', {disabled: selected_num <= 0}]"
            @click="store_page"
          >
            下一步
          </div>
        </div>
      </div>

      <van-popup
        v-model="show_tips"
        round
        position="bottom"
        :style="{ maxHeight: '50%' }"
      >
        <div class="tips_box">
          <div class="tips_title">{{ tips_name }}</div>
          <div class="tips_out">
            <van-skeleton title :row="10" :loading="loading">
              <div class="tips_text">{{ tips_text }}</div>
            </van-skeleton>
          </div>
          <div class="tips_btn" @click="close_tips">确定</div>
        </div>
      </van-popup>

      <van-popup
        v-model="show_leasts"
        round
        position="bottom"
        :style="{ maxHeight: '70%' }"
      >
        <div class="pop_least_box">
          <div class="pop_header">
            <div class="pop_title">可多选</div>
            <div class="pop_btn" @click="show_leasts = false">完成</div>
          </div>
          <div class="pop_main">
            <div v-for="(group, index) in pop_data.arr" :key="index">
              <div v-if="group.group_name" class="pop_group_name">
                {{ group.group_name }}：
              </div>
              <div class="group_out">
                <div
                  :class="['group_items', {active: item.exist === true}]"
                  v-for="(item, idx) in group.items"
                  :key="idx"
                  @click.stop="switch_pop_data(item)"
                >
                  {{ item.display }}
                </div>
              </div>
            </div>
            <!-- <div :class="pop_data.cancel ? 'active' : ''" @click="cancel_all(pop_data)">其他</div> -->
          </div>
        </div>
      </van-popup>

      <van-popup
        v-model="showFamily"
        round
        position="bottom"
        :style="{ minHeight: '15%', maxHeight: '80%' }"
      >
        <div class="family_box" v-if="family&&family.members&&family.members.length">
          <div class="family_title">切换家人</div>
          <div
            class="family_card"
            v-for="(item, idx) in family.members.filter(item=>{return !item.default_member})"
            :key="idx"
          >
            <div class="family_head">
              <div class="img_out">
                <img
                  :src="get_user_head(item.relationship, item.gender)"
                  alt=""
                  width="41"
                />
              </div>
              <div>{{ item.relationship_label }}</div>
            </div>

            <div class="family_info">
              <p class="family_name">{{ item.name }}</p>
              <p>健康卡: {{ format_medical_card(item.medical_card_no) }}</p>
            </div>
            <div class="family_btn" @click="chooseFamily(item)">选择</div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="show_hurry" overlay-class="hurry_pop">
        <div class="hurry_box">
          <img
            class="hurry_bg"
            src="../../assets/img/hurry_pop_bg.png"
            width="80"
            alt=""
          />
          <p class="hurry_title">症状风险较高</p>
          <p class="hurry_text">请尽快前往医院就诊或拨打急救电话120</p>
          <p class="hurry_btn" @click="show_hurry = false">已知晓风险</p>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  consultation_list,
  introduction_text,
  family_info,
  last_consultation,
  store_consultation,
  set_default_relation,
  cancel_last,
} from "./service";

import { mapMutations } from "vuex";
import { relation_list, relation_imgs, img_arr } from "./config";
import { format_birthDay, format_medical_card } from "../../utils/format";
import { get_user_head } from '../../utils/imgUrl'

export default {
  data() {
    return {
      data: {},
      loading: false,
      title_index: -1,
      symptoms_index: "",
      symptoms: [],
      sub_symptoms: [],
      show_tips: false,
      tips_name: "--",
      tips_text: "",
      show_leasts: false,
      pop_data: {
        cancel: false,
        arr: [],
      },
      family: {},
      origin: {},
      showFamily: false,
      relation_list,
      relation_imgs,
      img_arr,
      normal: [],
      normal_num: 0,
      cancel_id: "",
      value: "",
      timer: null,
      normal_show: true,
      show_hurry: false,
      from: "",
      selected_num: 0,
    };
  },
  computed: {},
  components: {},
  watch: {
    value(new_value, old_value) {
      if (new_value !== old_value) {
        this.normal_show = true;
        this.data.parts.forEach((el) => {
          el.show = true;
          el.symptoms.forEach((item) => {
            item.show = true;
          });
        });
        this.loading = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(this.input, 500);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/ai-doctor") {
        vm.from_ai();
      } else if (from.path === "/online-chat") {
        vm.get_consultation()
      } else {
        vm.get_last();
      }
    });
  },
  created() {
    this.from = this.$route.query.from;
    this.clearState();
    this.get_family();
  },
  methods: {
    ...mapMutations("illList", ["clearState"]),
    ...mapMutations("illList", ["updateUser"]),
    ...mapMutations("illList", ["updateIll"]),
    format_birthDay,
    format_medical_card,
    get_user_head,
    async store_page() {
      this.loading = true;
      try {
        let arr = [];
        // 在线问诊
        let online_arr = [];
        this.data.parts.forEach((it) => {
          // 在线问诊
          let online_obj = {
            standard_name: "",
            standard_id: "",
            children: [],
          };
          it.symptoms.forEach((el) => {
            let obj = {
              name: "",
              arr: [],
            };
            let online_obj_2 = {
              standard_name: "",
              standard_id: "",
              children: [],
            };
            if (el.exist) {
              obj.name = el.name;
              //在线问诊
              online_obj_2 = {
                ...online_obj_2,
                standard_name: el.name,
                standard_id: el.id,
              };
              if (el.sub_symptoms) {
                //在线问诊
                el.sub_symptoms.forEach((it) => {
                  it.items.forEach((item) => {
                    if (item.exist) {
                      //在线问诊
                      online_obj_2.children.push({
                        standard_name: item.name,
                        standard_id: item.id,
                      });
                      obj.arr.push(item.display);
                    }
                  });
                });
              }
              arr.push(obj);
              //在线问诊
              online_obj.children.push(online_obj_2);
            }
          });
          //在线问诊
          if (online_obj.children.length > 0) {
            online_obj.standard_name = it.name;
            online_obj.standard_id = it.name;
            online_arr.push(online_obj);
          }
        });
        console.log(arr)
        if (arr.length) {
          if (!this.from) {
            this.updateIll(arr);
            const { data } = await store_consultation({
              id: this.data.id,
              parts: this.data.parts,
            });
            this.$router.push({
              path: "/ai-doctor",
              query: { id: data.id, pregnancy: this.pregnancy },
            });
          } else {
            //在线问诊

            this.updateIll(online_arr);
            await store_consultation({
              id: this.data.id,
              parts: this.data.parts,
            });
            this.$router.go(-1);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async from_ai() {
      this.loading = true;
      try {
        const { data } = await last_consultation();
        if (!data.parts || !data.parts.length) {
          this.get_consultation();
        } else {
          this.add_num(data);
          this.data = data;
          this.pregnancy = data.pregnancy;
          //this.get_symptoms(this.title_index);
        }
      } finally {
        this.loading = false;
      }
    },

    async cancel_check(id) {
      this.loading = true;
      try {
        await cancel_last(id);
      } finally {
        this.loading = false;
      }
    },

    async get_last() {
      this.loading = true;
      try {
        const { data } = await last_consultation();
        this.cancel_id = data.id;
        if (!data.parts || !data.parts.length) {
          this.get_consultation();
        } else {
          this.$dialog
            .confirm({
              message: "是否继续上次问诊？",
              confirmButtonColor: "#0088FF",
            })
            .then(() => {
              this.add_num(data);
              this.data = data;
              this.pregnancy = data.pregnancy;
              //this.get_symptoms(this.title_index);
            })
            .catch(() => {
              this.cancel_check(this.cancel_id);
              this.pregnancy = null;
              this.get_consultation();
            });
        }
      } finally {
        this.loading = false;
      }
    },
    async get_family() {
      this.loading = true;
      try {
        const { data } = await family_info();
        this.family = data;
        this.origin = this.family.members.filter(item=>{
          return item.default_member
        })[0]
        this.updateUser(this.origin);
      } finally {
        this.loading = false;
      }
    },
    async chooseFamily(item) {
      this.loading = true;
      try {
        await set_default_relation({
          medical_card_no: item.medical_card_no,
        });
        this.get_family()
        this.$nextTick(() => {
          this.showFamily = false;
          this.updateUser(this.origin);
          this.data.id = "";
          this.get_consultation(true);
        });
      } finally {
        this.loading = false;
      }
    },
    add_num(data, again) {
      if (again) this.selected_num = 0;
      let all_symptoms = [];
      data.parts.forEach((el, idx) => {
        el.show = true;
        el.symptoms.forEach((item) => {
          if (again || item.exist === undefined) {
            item.exist = false;
          } else if (item.exist) {
            this.selected_num++;
          }
          if (item.normal) {
            item.last_index = idx;
          }
          all_symptoms.push(item);
          if (item.sub_symptoms) {
            item.sub_symptoms.forEach((group) => {
              if (group.items && group.items.length) {
                group.items.forEach((it) => {
                  if (it.exist === undefined) {
                    it.exist = false;
                  }
                });
              }
            });
          }
          item.show = true;
        });
        el.num = el.symptoms.filter((item) => {
          return item.exist;
        }).length;
      });
      this.normal = all_symptoms.filter((item) => {
        return item.normal;
      });
      this.normal_num = this.normal.filter((item) => {
        return item.exist;
      }).length;
    },
    async get_consultation(again) {
      this.loading = true;
      try {
        const { data } = await consultation_list();
        this.add_num(data, again);
        this.data = data;
        if (again && this.title_index > -1) {
          this.get_symptoms(this.title_index);
        }
      } finally {
        this.loading = false;
      }
    },
    async get_introduction(id) {
      this.loading = true;
      try {
        const { data } = await introduction_text(id);
        this.$nextTick(() => {
          // this.tips_text = data.introduction;
          this.tips_text = data;
          this.show_tips = true;
        });
      } finally {
        this.loading = false;
      }
    },

    switch_title(item, idx) {
      this.title_index = idx;
      this.get_symptoms(idx);
    },
    get_symptoms(idx) {
      if (idx > -1) {
        this.symptoms = this.data.parts[idx].symptoms;
      }
    },
    switch_symptoms(item) {
      if (!item.exist && item.danger) {
        this.show_hurry = true;
      }
      //this.symptoms_index = idx;
      item.exist = !item.exist;
      if (this.normal.indexOf(item) > -1) {
        item.exist ? this.normal_num++ : this.normal_num--;
      }
      item.exist
        ? this.data.parts[
          this.title_index < 0 ? item.last_index : this.title_index
        ].num++
        : this.data.parts[
          this.title_index < 0 ? item.last_index : this.title_index
        ].num--;
      if (item.sub_symptoms && item.sub_symptoms.length) {
        this.show_leasts = item.exist;
        this.show_pop_data(item);
      }
      this.selected_num = item.exist
        ? (this.selected_num += 1)
        : (this.selected_num -= 1);
    },
    open_tips(item) {
      this.tips_name = item.name;
      this.get_introduction(item.id);
    },
    close_tips() {
      this.show_tips = false;
    },
    remove_least(item) {
      item.exist = false;
    },
    show_pop_data(item) {
      this.pop_data.arr = item.sub_symptoms;
      this.pop_data.cancel = false;
    },
    switch_pop_data(item) {
      if (!item.exist && item.danger) {
        this.show_hurry = true;
      }
      item.exist = !item.exist;
      this.pop_data.cancel = false;
    },
    // cancel_all(data) {
    //   this.pop_data.cancel = true
    //   data.data.forEach((i) => {
    //     i.exist = false;
    //   });
    // },
    input() {
      let normal_arr = [];
      this.normal.forEach((item) => {
        if (!item.name.includes(this.value)) {
          item.show = false;
        } else {
          normal_arr.push(item);
        }
      });
      if (!normal_arr.length) {
        this.normal_show = false;
      }

      let first = [];
      this.data.parts.forEach((el) => {
        let data_arr = [];
        el.symptoms.forEach((item) => {
          if (!item.name.includes(this.value)) {
            item.show = false;
          } else {
            data_arr.push(item);
            if (first.indexOf(el) < 0) {
              first.push(el);
            }
          }
        });
        if (!data_arr.length) {
          el.show = false;
        }
      });
      if (normal_arr.length) {
        this.switch_title(null, -1);
      } else if (first.length) {
        this.switch_title(first[0], this.data.parts.indexOf(first[0]));
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.g_main_content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.header {
  display: flex;
  height: 44px;
  background: #e5f3ff;
  opacity: 1;
  padding: 0 16px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #565656;
  align-items: center;
  justify-content: space-between;

  & div {
    margin-right: 25px;
  }

  & img {
    justify-self: flex-end;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}

.title_box {
  width: 42%;
  background: #ffffff;
  overflow-y: auto;
}

.title {
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #565656;
  position: relative;

  & p {
    margin-left: 10px;
  }

  &.active {
    background: #f5f5f5;
  }
}

.badge {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  background: #ff7823;
  border-radius: 50%;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
}

.symptoms_box {
  width: 58%;
  background: #f5f5f5;
  overflow-y: auto;

  &.s2 {
    padding-top: 7px;
  }
}

.sub_title {
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  font-weight: 400;
  color: #707070;
}

.sub_symptoms_box {
  padding: 0 16px;
}

.sub_outer {
  margin-bottom: 15px;
}

.sub_symptoms {
  line-height: 18px;
  background: #ffffff;
  opacity: 1;
  border-radius: 22px;
  padding: 8px 0 8px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #565656;

  & .dots_img {
    width: 18px;
    height: 18px;
    float: right;
    margin: 0 15px 0 0;
    background: url("../../assets/img/consultation/dots.png") no-repeat center;
    background-size: contain;
  }

  &.active {
    background: #1890ff;
    color: #ffffff;

    & .dots_img {
      background: url("../../assets/img/consultation/dots-active.png") no-repeat
        center;
      background-size: contain;
    }
  }
}

.tips_box {
  padding: 18px 22px 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
}

.tips_title {
  text-align: center;
}

.tips_out {
  margin: 15px 0;
  max-height: 199px;
  overflow-y: auto;
}

.tips_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}
.tips_btn {
  height: 41px;
  background: #1890ff;
  opacity: 1;
  border-radius: 21px;
  font-size: 14px;
  font-weight: 400;
  line-height: 41px;
  color: #ffffff;
  text-align: center;
}

.least_symptoms {
  min-height: 36px;
  background: #ffffff;
  opacity: 1;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #565656;
  padding: 0 18px;
  display: flex;
  align-items: center;
  margin: 15px 20px 12px 14px;
}

.close_least {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background: url("../../assets/img/consultation/least-close.png") no-repeat
    center;
  background-size: contain;
}

.pop_least_box {
  padding: 22px 16px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #565656;
}

.pop_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #cccccc;
}

.pop_btn {
  width: 52px;
  background: #ffffff;
  border: 1px solid #0088ff;
  opacity: 1;
  border-radius: 15px;
  text-align: center;
  line-height: 27px;
  font-size: 12px;
  font-weight: 400;
  color: #0088ff;
}

.pop_main {
  padding-top: 20px;
  max-height: 50vh;
  overflow-y: auto;

  & .group_out {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & .group_items {
    padding: 0 14px;
    height: 32px;
    border: 1px solid #eaeaea;
    opacity: 1;
    border-radius: 19px;
    margin: 0 18px 18px 0;

    &.active {
      color: #ffffff;
      background: #0088ff;
    }
  }
}

.bottom_box {
  padding: 13px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #242938;
  background: #ffffff;
  -webkit-box-shadow: 0px 0.03rem 0.08rem rgb(0 0 0 / 16%);
  box-shadow: 0px 0.03rem 0.08rem rgb(0 0 0 / 16%);
}

.bottom_other {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}

.bottom_btn {
  width: 92px;
  height: 30px;
  background: #1890ff;
  opacity: 1;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  margin-left: 25px;

  &.disabled {
    background: #ccc;
  }
}

.family_box {
  padding: 25px;
}

.family_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #242938;
}

.family_card {
  margin-top: 24px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  margin-right: 20px;

  & div {
    width: 43px;
    height: 17px;
    background: #ffa700;
    opacity: 1;
    border-radius: 9px;
    text-align: center;
    margin-top: -2px;
  }

  & .img_out {
    width: 45px;
    height: 45px;
    background: #edf5ff;
    border: 2px solid #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 28px;
    text-align: center;
    margin-top: 0;
  }
}

.family_info {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #707070;
  width: 150px;
}

.family_name {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
}

.family_btn {
  width: 66px;
  height: 30px;
  background: #ff7823;
  box-shadow: 0px 2px 8px rgba(255, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}

.hurry_box {
  width: 314px;
  height: 130px;
  opacity: 1;
  border-radius: 12px;
  font-size: 14px;
  color: #4f2623;
  padding: 28px 16px;
  line-height: 16px;
  background: #ffeaea;
  background-size: cover;
}

.hurry_bg {
  position: absolute;
  top: 0;
  right: 0;
}

.hurry_text {
  font-size: 12px;
  color: #745a66;
  line-height: 14px;
}

.hurry_btn {
  position: relative;
  width: 99px;
  height: 32px;
  background: #d16262;
  border-radius: 12px;
  margin-top: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
}

.hurry_pop + .van-popup {
  background-color: transparent;
}

.pop_group_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #3d3d3d;
  margin: 10px 0;
}
</style>
